(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/admin/dialog-adapter/assets/javascripts/prescript.js') >= 0) return;  svs.modules.push('/components/admin/dialog-adapter/assets/javascripts/prescript.js');

svs.components = svs.components || {};
svs.components.dialogAdapter = svs.components.dialogAdapter || {};

svs.admin = svs.admin || {};
svs.admin.shared = svs.admin.shared || {};
svs.admin.shared.message = svs.admin.shared.message || {};



 })(window);