(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/admin/dialog-adapter/assets/javascripts/helper.js') >= 0) return;  svs.modules.push('/components/admin/dialog-adapter/assets/javascripts/helper.js');
"use strict";


const {
  button,
  cmsType,
  icon,
  newDialog,
  text
} = svs.components.dialogWrapper.helper;

const Type = {
  INFO: 'info',
  ERROR: 'error',
  INFOERROR: 'infoerror',
  DEFAULT: 'info'
};

const cmsErrorMessage = (title, message) => {
  newDialog({
    buttons: [button({
      title: 'Ok'
    })],
    message: message ? message.map(mess => text({
      text: mess
    })) : [],
    title,
    icon: icon[cmsType.ERROR]
  });
};
svs.components.dialogAdapter.helper = {
  Type
};
svs.admin.shared.message.cmsErrorMessage = cmsErrorMessage;

 })(window);